<template>
    <div :class="cn(badgeVariants({ variant, fill }), props.class)">
        <slot />
    </div>
</template>
<script setup lang="ts">
import type { HTMLAttributes } from "vue";
import { type BadgeVariants, badgeVariants } from "~/components/ui";

const props = defineProps<{
    variant?: BadgeVariants["variant"];
    fill?: BadgeVariants["fill"];
    class?: HTMLAttributes["class"];
}>();
</script>
